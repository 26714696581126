@import "base/fonts";

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.clearfix:after,
.container:after,
.adr_sec:after,
.art_img_sec:after {
  content: ' ';
  clear: both;
  display: block;
  width: 0;
  height: 0;
  overflow: hidden;
  font-size: 0;
}
html,
body {
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}
html {
  font: 300 18px/1.23 'MuseoSansCyrl', Arial, Helvetica, sans-serif;
  color: #080808;
}
body {
  text-align: left;
  background: #fff;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  max-height: 9001em;
}
fieldset,
form,
figure,
legend {
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
img {
  border: 0;
  vertical-align: top;
  max-width: 100%;
  height: auto;
}
mark {
  color: #000;
  background: #ff0;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
b,
strong {
  font-weight: bold;
}
hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  background: #080808;
  height: 1px;
  opacity: 0.15;
  border: none;
  margin: 10px 0;
}
pre {
  overflow: auto;
  background-color: #f5f5f5;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
dfn,
address {
  font-style: normal;
}
/* Input */
input,
textarea,
select,
button {
  font: normal 15px 'MuseoSansCyrl', Arial, Helvetica, sans-serif;
  color: #2c2c2c;
  outline: none;
  line-height: normal;
  background: none;
}
input,
textarea,
select {
  margin: 0;
  border: 1px solid #d9d9d9;
  outline: none;
  border-radius: 4px;
  padding: 8px 15px;
}
input:focus,
textarea:focus {
  border-color: #676767;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: none;
  cursor: pointer;
}
button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner {
  padding: 0;
  border: 0 none;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
input[type="checkbox"] + label,
input[type="radio"] + label {
  cursor: pointer;
}
::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1;
}
::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}
:-moz-placeholder {
  color: #ccc;
  opacity: 1;
}
:-ms-input-placeholder {
  color: #ccc;
  opacity: 1;
}
textarea {
  resize: none;
}
[hidden],
template {
  display: none;
}
.input {
  margin-bottom: 10px;
}
.input input,
.input textarea,
.input select {
  width: 100%;
}
.input input[type="button"],
.input input[type="reset"],
.input input[type="submit"],
.input input[type="checkbox"],
.input input[type="radio"] {
  width: auto;
}
/* END Input */
/* Table */
table {
  width: 100%;
  border-collapse: collapse;
}
td,
th {
  vertical-align: middle;
  text-align: left;
  border: 1px solid #333;
  padding: 9px 10px;
}
.table-responsive {
  width: 100%;
}
.table-responsive table {
  margin-bottom: 0 !important;
}
/* END Table */
p,
article {
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
p,
ul,
ol,
table,
form,
.table-responsive {
  margin: 0 0 22px;
}
ul,
ol {
  padding: 0 0 0 18px;
}
li {
  margin: 0 0 9px;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: bold;
  line-height: 1.2;
  font-size: 36px;
  color: #1f1f1f;
  margin: 0 0 51px;
}
h1,
.h1 {
  text-align: center;
}
h2,
.h2 {
  font-size: 36px;
  text-align: center;
}
h3,
.h3 {
  font-size: 21px;
}
h4,
.h4 {
  font-size: 21px;
}
h5,
.h5 {
  font-size: 21px;
}
h6,
.h6 {
  font-size: 21px;
}
h3,
h4,
h5,
h6,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 8px;
}
a {
  cursor: pointer;
  color: #2580cc;
  text-decoration: none;
  outline: none;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
a:hover {
  text-decoration: none;
  color: #FF2E2E;
}
.nowrap {
  white-space: nowrap;
}
.nofloat {
  height: 100%;
  overflow: hidden;
}
.inline {
  display: inline-block;
  vertical-align: top;
}
.outtaHere {
  display: none;
  position: absolute;
  left: -10000px;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.txt-divide {
  margin-bottom: 10px;
}
.flex,
.header .header_hold,
.header .header_l,
.header .header_r,
.main_menu ul,
.reg_list,
.footer_hold,
.footer_nav ul,
.nav_content {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.half {
  width: 48%;
  flex-shrink: 0;
}
.wrapper,
.footer {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}
.wrapper {
  min-height: 100vh;
}
.container {
  width: 990px;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
}
.content:after {
  content: ' ';
  clear: both;
  display: block;
  width: 0;
  height: 0;
  overflow: hidden;
  font-size: 0;
}
.video {
  width: 100%;
  margin: 0 auto 17px;
  overflow: hidden;
  text-align: center;
  position: relative;
  padding-bottom: 56%;
}
.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.phone_num {
  cursor: default;
  color: #333;
  text-decoration: none;
}
.btn {
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  max-width: 100%;
  min-width: 100px;
  position: relative;
  z-index: 1;
  line-height: 1.2;
  height: 30px;
  min-height: 30px;
  color: #2580cc;
  font-size: 15px;
  font-weight: 300;
  padding: 5px 22px;
  border: 1px solid #2580cc;
  border-radius: 5px;
  background: none;
}
.btn:hover {
  text-decoration: none;
  color: #2580cc;
}
.btn:active {
  position: relative;
  top: 1px;
}
.btn .btn_in {
  position: relative;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 2;
}
/* ripple_effect */
.ink {
  display: block;
  position: absolute;
  border-radius: 100%;
  transform: scale(0);
  background: #2580cc;
  opacity: 0.6;
  z-index: 1;
}
/*animation effect*/
.ink.animate {
  animation: ripple 0.65s linear;
  -webkit-animation: ripple 0.65s linear;
}
@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}
@-webkit-keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}
/* end ripple_effect */
/* H E A D E R */
.slideout-menu {
  display: none !important;
}
#menu_label,
#slide_panel_mobile_menu,
.mobile_menu {
  display: none;
}
.header {
  position: relative;
  padding: 20px 0;
  background: #f5f5f5;
  margin-bottom: 87px;
}
.header .header_hold,
.header .header_l,
.header .header_r {
  align-items: center;
}
.header .header_r {
  padding-top: 2px;
}
.logo {
  font-size: 21px;
  font-weight: 700;
  white-space: nowrap;
  text-decoration: none;
  color: #080808;
}
.logo:hover {
  text-decoration: none;
  color: #080808;
}
a.logo:hover {
  color: #2580cc;
}
/* M A I N _ M E N U */
.main_menu {
  padding: 0;
  margin: 0 0 0 27px;
}
.main_menu ul {
  margin: 0 0 0 10px;
  padding: 0;
  list-style: none;
}
.main_menu ul li {
  margin: 0 0 0 30px;
  padding: 0;
  position: relative;
  text-align: center;
}
.main_menu ul li .menu_item {
  font-size: 15px;
  line-height: 1;
  color: #858585;
  text-decoration: none;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.main_menu ul .menu_item:hover,
.main_menu ul .active .menu_item {
  color: #2580cc;
}
/* end M A I N _ M E N U */
.close_btn {
  display: block;
  width: 20px;
  height: 20px;
  position: relative;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  cursor: pointer;
}
.close_btn:before,
.close_btn:after {
  content: '';
  display: block;
  position: absolute;
  background: #ff2e2e;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.close_btn:before {
  height: 2px;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -1px;
}
.close_btn:after {
  width: 2px;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -1px;
}
.lang_ch_hold {
  position: relative;
  color: #080808;
  font-size: 15px;
}
.lang_ch_hold .lang_ch_txt {
  padding: 0 27px 0 10px;
  position: relative;
}
.lang_ch_hold .lang_ch_txt:before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 4px 0 4px;
  border-color: #080808 transparent transparent transparent;
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 10px;
  opacity: 0.54;
}
.lang_ch_hold ul {
  margin: 0;
  padding: 5px 0;
  list-style: none;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  background: #fff;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate(0, 10px);
  -ms-transform: translate(0, 10px);
  transform: translate(0, 10px);
  z-index: -1;
}
.lang_ch_hold ul li {
  margin: 0;
  padding: 5px 10px;
}
.lang_ch_hold ul li a {
  display: block;
  color: #080808;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
}
.lang_ch_hold ul li a:hover {
  color: #2580cc;
  text-decoration: none;
}
.lang_ch_hold ul #li_close {
  display: none;
}
.lang_ch_hold .lang_ch_sm {
  display: none;
}
.lang_ch_hold:hover ul {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  z-index: 100;
}
.reg_list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 15px;
  justify-content: flex-end;
  align-items: center;
}
.reg_list li {
  margin: 0;
  padding: 0;
}
.header .reg_list {
  margin-left: 15px;
}
.header .reg_list li {
  flex-shrink: 0;
  margin-left: 21px;
}
/* end H E A D E R */
/* F O O T E R */
.footer {
  font-size: 15px;
}
.footer_hold {
  flex-wrap: nowrap;
  align-items: center;
  padding: 50px 0 42px;
  position: relative;
}
.footer_nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  justify-content: flex-start;
  align-items: center;
}
.footer_nav ul li {
  margin: 0 30px 0 0;
  padding: 0;
}
.footer_nav ul li:first-child {
  margin-right: 70px;
}
.footer_nav ul li a {
  text-decoration: none;
  color: #4d4d4d;
}
.footer_nav ul li a:hover {
  color: #2580cc;
  text-decoration: none;
}
.footer_nav ul li .f_mail {
  color: #2580cc;
}
.footer_nav ul li .f_mail:hover {
  color: #ff2e2e;
}
.dotten {
  display: inline-block;
  white-space: nowrap;
  font-size: 0;
  line-height: 0;
  text-align: right;
}
.dotten span {
  width: 7px;
  height: 7px;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  background: #777;
  margin-left: 4px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.dotten:hover span {
  background: #2580cc;
}
/* end F O O T E R */
.map {
  overflow: hidden;
  border-radius: 10px;
  height: 414px;
  margin: 21px 0;
}
.adr_sec {
  margin-bottom: 58px;
}
.adr_sec .adr {
  margin-right: 15px;
}
.art_img_sec {
  margin-bottom: 98px;
}
.art_img_sec .img {
  float: left;
  margin: 0 64px 15px 0;
  padding-left: 40px;
  text-align: right;
  width: 35.4%;
}
.art_img_sec .nofloat {
  padding-top: 2px;
}
.inf_blk,
.inf_blk2,
.inf_blk3 {
  display: block;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.16);
  margin: 0 0 15px;
  padding: 37px 48px 48px;
  border-radius: 10px;
  overflow: hidden;
  font-size: 15px;
  line-height: 1.2;
}
.inf_blk ul,
.inf_blk2 ul,
.inf_blk3 ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.inf_blk ul li,
.inf_blk2 ul li,
.inf_blk3 ul li {
  margin: 0 0 7px;
  padding: 0;
}
.inf_blk .h3,
.inf_blk2 .h3,
.inf_blk3 .h3,
.inf_blk .txt2,
.inf_blk2 .txt2,
.inf_blk3 .txt2 {
  color: #080808;
}
.inf_blk .txt,
.inf_blk2 .txt,
.inf_blk3 .txt {
  color: #858585;
}
.inf_blk .img_blk,
.inf_blk2 .img_blk,
.inf_blk3 .img_blk {
  background: #e0e0e0;
  height: 264px;
  overflow: hidden;
  margin: 26px 0 0;
}
.inf_blk .img_blk img,
.inf_blk2 .img_blk img,
.inf_blk3 .img_blk img {
  height: 100%;
  max-width: none;
  min-width: 100%;
}
.inf_blk .inf_blk_list,
.inf_blk2 .inf_blk_list,
.inf_blk3 .inf_blk_list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 16px;
  line-height: 1.19;
}
.inf_blk .inf_blk_list li,
.inf_blk2 .inf_blk_list li,
.inf_blk3 .inf_blk_list li {
  margin: 0;
  padding: 20px 0;
  border-top: 1px solid #e0e0e0;
}
.inf_blk .inf_blk_list li:first-child,
.inf_blk2 .inf_blk_list li:first-child,
.inf_blk3 .inf_blk_list li:first-child {
  border: none;
}
.inf_blk2 {
  border-radius: 5px;
  padding: 37px 32px 43px;
}
.inf_blk3 {
  border-radius: 5px;
  padding: 0 28px;
}
a.inf_blk:hover .h3,
a.inf_blk2:hover .h3,
a.inf_blk3:hover .h3,
a.inf_blk:hover .txt,
a.inf_blk2:hover .txt,
a.inf_blk3:hover .txt,
a.inf_blk:hover .txt2,
a.inf_blk2:hover .txt2,
a.inf_blk3:hover .txt2 {
  color: #2580cc;
}
.small {
  font-size: 13px;
  line-height: 1.15;
}
.info_sec,
.info_sec2 {
  margin: 0 auto 70px;
  width: 680px;
  max-width: 100%;
}
.info_sec h1,
.info_sec2 h1,
.info_sec h2,
.info_sec2 h2 {
  margin-bottom: 41px;
}
.info_sec .small,
.info_sec2 .small {
  display: block;
  color: #858585;
  padding: 32px 0 0;
}
.info_sec2 {
  width: 585px;
  max-width: 100%;
}
.arr_big {
  display: inline-block;
  height: 18px;
  overflow: hidden;
  margin-right: 6px;
  position: relative;
  top: 1px;
}
.arr_big img {
  height: 100%;
  width: auto;
}
.arr_sm {
  display: inline-block;
  height: 13px;
  overflow: hidden;
  margin: 2px 12px 0 12px;
}
.arr_sm img {
  height: 100%;
  width: auto;
}
.nav_content {
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 27px;
  padding-top: 36px;
}
.nav_content .n_c_t {
  flex-shrink: 0;
  color: #080808;
  font-weight: 700;
  font-size: 21px;
}
.nav_content a.n_c_t {
  color: #858585;
  font-weight: 300;
}
.nav_content a.n_c_t:hover {
  color: #ff2e2e;
}
.circle_list {
  margin-top: 5px!important;
  list-style-type: circle!important;
  margin-left: 30px!important;
}
/* R E S P O N S I V E */
@media screen and (max-width: 990px) {
  .container {
    width: 100%;
  }
  .table-responsive {
    overflow-y: hidden;
    overflow-x: auto;
  }
  /* S L I D E  M O B I L E  M E N U */
  .main_menu {
    display: none !important;
  }
  .mobile_menu {
    display: block;
    position: fixed;
    max-height: 95vh;
    background: #fff;
    top: 10px;
    left: 10px;
    right: 10px;
    padding: 0;
    margin: 0;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.16);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate(0, 10px);
    -ms-transform: translate(0, 10px);
    transform: translate(0, 10px);
    z-index: -1;
  }
  .mobile_menu .mobile_menu_hold {
    margin: 45px 0;
    overflow: hidden;
    overflow-y: auto;
  }
  .mobile_menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
  }
  .mobile_menu ul li {
    margin: 0;
    padding: 0;
    position: relative;
  }
  .mobile_menu ul li .mob_menu_item {
    display: block;
    font-size: 17px;
    line-height: 1;
    padding: 12px 15px;
  }
  .mobile_menu ul li .mob_menu_item,
  .mobile_menu ul li .mob_menu_item a {
    text-decoration: none;
    color: #2580cc;
    display: block;
    padding: 0.625rem 10px;
    text-align: center;
    font-size: 1.5625rem;
    font-weight: 500;
  }
  .mobile_menu ul li .mob_menu_item:hover,
  .mobile_menu ul li.active > .mob_menu_item,
  .mobile_menu ul li.active > .mob_menu_item a {
    color: #ff2e2e;
  }
  .mobile_menu ul li ul {
    padding-left: 20px;
    margin-bottom: 0;
  }
  .mobile_menu .close_btn {
    position: absolute;
    top: 15px;
    right: 14px;
  }
  .mobile_menu.open {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    z-index: 100;
  }
  /* SLIDE MENU TOGGLE */
  #menu_label {
    display: block;
    cursor: pointer;
    position: fixed;
    top: 24px;
    right: 10px;
    z-index: 99;
  }
  #menu_label.open {
    display: none;
  }
  /* ICON */
  #nav-toggle {
    width: 33px;
    height: 28px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }
  #nav-toggle span:before,
  #nav-toggle span:after {
    content: '';
  }
  #nav-toggle span,
  #nav-toggle span:before,
  #nav-toggle span:after {
    height: 2px;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    background: #2580cc;
    border-radius: 3px;
    cursor: pointer;
  }
  #nav-toggle span {
    transition-duration: 0.1s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    left: 5px;
    right: 5px;
    top: 13px;
  }
  #nav-toggle span:before {
    transition: top 0.1s 0.14s ease, opacity 0.1s ease;
    top: -6px;
  }
  #nav-toggle span:after {
    transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    bottom: -6px;
  }
  .slideout-open #nav-toggle span {
    transform: rotate(45deg);
    transition-delay: 0.14s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    background: #ff2e2e;
  }
  .slideout-open #nav-toggle span:before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease, opacity 0.1s 0.14s ease;
  }
  .slideout-open #nav-toggle span:after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
    background: #ff2e2e;
  }
  .mobile_menu .reg_list,
  .mobile_menu .lang_ch_hold {
    display: none;
  }
  /* end S L I D E  M O B I L E  M E N U */
  .header {
    padding: 10px 0;
    margin-bottom: 40px;
  }
  .header .header_hold {
    height: 56px;
    min-height: 56px;
  }
  .header .header_r {
    padding-right: 50px;
  }
  h1,
  h2,
  .h1,
  .h2 {
    margin-bottom: 30px !important;
  }
  .art_img_sec {
    margin-bottom: 40px;
  }
  .footer .dotten {
    position: absolute;
    top: 15px;
    left: 50%;
    margin: 0 0 0 -17px;
  }
  .footer_nav {
    width: 100%;
  }
  .footer_nav ul {
    flex-direction: column;
    align-items: center;
  }
  .footer_nav ul li {
    margin: 0 0 10px !important;
  }
  .footer_hold {
    flex-direction: column;
    padding: 42px 0 20px;
  }
  .nav_content {
    padding-top: 15px;
  }
  .info_sec .small,
  .info_sec2 .small {
    padding-top: 10px;
  }
  .lang_ch_hold {
    position: static;
  }
  .lang_ch_hold .lang_ch_lg {
    display: none;
  }
  .lang_ch_hold .lang_ch_sm {
    display: inline;
  }
  .lang_ch_hold ul {
    top: 0;
    left: 10px;
    right: 10px;
    min-width: 1px;
    background: #fff;
    font-size: 1.5625rem;
    font-weight: 500;
    padding: 45px 0;
    overflow: hidden;
    overflow-y: auto;
  }
  .lang_ch_hold ul li a {
    text-align: center;
    padding: 0.625rem 10px;
    color: #2580cc;
  }
  .lang_ch_hold ul li a:hover {
    color: #ff2e2e;
  }
  .lang_ch_hold ul #li_close {
    display: block;
    position: absolute;
    top: 10px;
    right: 5px;
  }
  .lang_ch_hold:hover ul {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate(0, 10px);
    -ms-transform: translate(0, 10px);
    transform: translate(0, 10px);
    z-index: -1;
  }
  .lang_ch_hold.open ul {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    z-index: 300;
  }
  .header .reg_list,
  .lang_ch_hold .lang_ch_txt:before {
    display: none;
  }
  .lang_ch_hold .lang_ch_txt {
    padding: 0 4px;
  }
  .mobile_menu .reg_list {
    padding: 0 5px;
    margin: 0 0 10px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: flex-end;
  }
  .mobile_menu .reg_list li {
    margin: 10px;
  }
}
/* end 990px */
@media screen and (max-width: 667px) {
  html {
    font-size: 16px;
  }
  .half {
    width: 48%;
  }
  .adr_sec,
  .info_sec,
  .info_sec2 {
    margin-bottom: 30px;
  }
  .art_img_sec .img {
    float: none;
    margin: 0 auto 20px;
    padding-left: 0;
    text-align: center;
    width: 290px;
    max-width: 100%;
  }
  .map {
    height: 300px;
  }
}
/* end 667px */
