@import "../abstracts/functions";

@font-face {
  font-family: 'MuseoSansCyrl';
  src: font('MuseoSansCyrl-300.ttf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSansCyrl';
  src: font('MuseoSansCyrl-500.ttf') format('opentype');
  font-weight: 500;
  font-style: normal;

}

@font-face {
  font-family: 'MuseoSansCyrl';
  src: font('MuseoSansCyrl-700.ttf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "PT Mono";
  src: font('pt-mono-regular.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}


@mixin font-sans-serif-light {
  font-family: "MuseoSansCyrl", Helvetica, Arial, sans-serif;
  font-weight: 300;
}

@mixin font-sans-serif-medium {
  font-family: "MuseoSansCyrl", Helvetica, Arial, sans-serif;
  font-weight: 500;
}

@mixin font-sans-serif-bold {
  font-family: "MuseoSansCyrl", Helvetica, Arial, sans-serif;
  font-weight: 700;
}

@mixin font-mono {
  font-family: "PT Mono", Helvetica, Arial, sans-serif !important;
}

.mono-font {
  @include font-mono;
}
